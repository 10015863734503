.history-processing {
    color: #31708f;
}

.history-success {
    color: #3c763d;
}

.history-failed {
    color: #a94442;
}
