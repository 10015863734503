body {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    background-color: #f5f5f5;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
h1 {
    font-size: 36px;
}
h1.bordered {
    border-bottom: 1px solid #CCC;
    margin-bottom: .24em;
}
h1 a,
h2 a
{
    display: inline-block;
    margin-left: .75em;
    border-left: 1px solid #CCC;
    padding-left: 1em;
    font-size: 18px;
    padding-top: 12px;
    line-height: 24px;
}
h2 a {
    margin-left: .75em;
    border-left: 1px solid #CCC;
    padding-left: 1em;
    font-size: 14px;
    line-height: 14px;
}
h4 {
    clear: both;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    padding: 1em 0;
}
h4.modal-title {
    padding: 0;
    clear: none !important;
}
h4.border-bottom {
    border-bottom: 1px solid #CCC;
    padding: .5em 0;
    margin: .5em 0 1em;
}
h5 {
    font-weight: 700;
    color: #666;
}
td ul {
    padding-left: 15px;
    padding-right: 15px;
}
dd {
    padding: 0 0 1em 0;
}
a.remove {
    color: #C00;
}
.modal h3 {
    background: #E1E1E1;
    color: #000;
    padding: .24em 1em;
    font-size: 16px;
    font-weight: 700;
    margin: 1.5em 0;
    text-align: center;
}
.no-padding {
    padding: 0;
}
.no-margin {
    margin: 0;
}
.total {
    font-weight: 700;
}
.help {
    color: #999;
}
.help-block {
    font-size: 14px;
    margin-bottom: .24em;
}
.tile {
    margin: 1em 0;
    background-color: #FFF;
    border: 1px solid #D1D1D1;
    padding: 0;
}

.row-tile h4 {
    font-size: 24px;
    padding: 0 0 0 .5em;
    margin-bottom: 1em;
    font-weight: 400;
    border-left: 4px solid #3aa4ff;
}
  
.sidebar-left {
    padding-left: 0;
}
.checkbox-inline, .radio-inline {
    padding-left: 0;
    padding-right: 20px;
}
.table .glyphicon-chevron-right {
    color: #337ab7;
}
.table>thead>tr>th {
    border-bottom: 2px solid #3aa4ff;
}
.nav-tabs {
    margin-top: 1em;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    border-width: 0;
    border-bottom: 4px solid #3aa4ff;
}
.btn-primary {
    border: 1px solid #3AA4FF;
    background-color: #3AA4FF;
    border: 0;
}
.btn-secondary {
    border: 1px solid #3AA4FF;
    color: #3AA4FF;
    background-color: #FFF;
}
.btn-secondary:hover {
    background-color: #CCC;
    color: #FFF;
    border-color: #CCC;
}
.btn-nin {
    background-color: #E60012;
    color: #FFF;
    border-radius: 0;
    border: 1px solid #E60012;
}
.btn-nin:hover {
    background-color: #FFF;
    color: #E60012;
}
.action-delete {
    color: #C00;
}
.page-link {
    margin: 2em 0;
}
.loading span {
    font-size: 24px;
    font-style: italic;
    color: #999;
}
.actions {
    clear: both;
    margin: 2em 0 0;
    padding: 1em 0 0;
}
.actions .btn,
.actions .btn
{
    font-size: 18px;
    padding: .5em 2em;
}
.result-showing {
    font-style: italic;
    padding: 0;
    margin: 25px 0;
    color: #666;
    font-size: 14px;
}
.table-hover tbody tr:hover {
    cursor: pointer;
}
tr.line-error td {
    border-top-width: 0 !important;
}

/* app specific */
.col-alert {
    padding-left: 0;
    padding-right: 0;
}
.row-tile {
    background-color: #FFF;
    padding: 2em 1em;
    border: 1px solid #E1E1E1;
    margin-top: 1em;
}
.form-search-bar {
    width: calc(100% - 75px);
    float:left;
    margin-right:4px;
}
td.action {
    text-align: right;
}
td.action a.remove {
    margin: .5em;
    display: inline-block;
}
.btn-container {
    margin: 100px 0 1em;
    border-top: 2px solid #E1e1e1;
    padding: 1.5em 0;
}
.shipmethod-wrapper {
    overflow: auto;
    background: #f9f9f9;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    padding: 1em;
    margin-bottom: 2em;
}
.shipmethod-wrapper .form-group {
    overflow: auto;
    margin: .5em 0;
}
.ui-autocomplete {
    z-index: 9999 !important;
}
.store-list {
    overflow: auto;
    list-style: none;
    margin: 0;
    padding: 0;
}
.store-list li {
    float: left;
    padding: 0 3em 3em 0;
}
dl {
    overflow: auto;
    margin-bottom: 0;
}
dl dt {
    clear: left;
    text-align: left;
    width: 200px;
    padding-right: 1em;
    font-weight: 700;
}

dl.striped {
    margin-left: 0;
    margin-right: 0;
}
dl.striped dt:nth-child(4n+1), dl.striped dd:nth-child(4n+2) {
    background-color: #F4F4F4;
}
dl.striped dd, dl.striped dt {
    margin-bottom: 0;
    padding: .5em 1em;
    word-break: break-word;
}

dd {
    font-weight: 400;
}
dt, dd {
    float: left;
    padding-bottom: 1em;
}
h4 {
    font-size: 20px;
}
.status-completed {
    color: #4f8e4f;
    font-weight: bold;
}
.status-progress {
    color: #f0ad4e;
    font-weight: bold;
}
span.notifications {
    display: inline-block;
    margin: 0 .5em;
    background-color: #FFF;
    color: #E20013;
    padding: 0 1em;
    border-radius: 10px;
}
span.new {
    display: inline-block;
    margin-right: 3px;
    background-color: yellow;
    color: #E20013;
    padding: 0 1em;
    border-radius: 10px;
    font-size: 12px;
    border: 1px solid #999;
    text-transform: uppercase;
}
td.edited span {
    font-size: 12px;
}
#tbl_queue_orders ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.top-actions {
    float: right;
}
/* nav highlights */
.page-orders .nav .orders a,
.page-queue .nav .queue a,
.page-admin .nav .admin a
{
    font-weight: 700;
}
#impersonateBar {
    overflow: auto;
    background-color: #fcf11b;
    padding: .24em 2em;
    color: #000;
    text-align: center;
    margin: -20px 0 20px;
    border-bottom: 1px solid #d1d1d1;
}
#impersonateBar a {
    color: #000;
    text-decoration: underline;
    font-weight: 300;
}

/* 2018 updates */
.glyphicon-question-sign {
    font-size: .85em;
    color: #CCC;
}
h2.product-title {
    border-bottom: 1px solid #CCC;
    margin-bottom: .24em;
    padding: 0 0 .24em;
    font-size: 36px;
}
h2.product-title span.platform {
    font-size: .75em;
    color: #999;
    margin: 0 0 0 .5em;
    font-weight: 400;
}
h2.product-title span.status-mini {
    margin-left: .75em;
}
h2.asset-title {
    display: inline-block;
    background: #2576bc;
    color: #FFF;
    padding: .24em .5em;
}
.nav li.login {
    margin: .5em 0;
}
.nav li.username {
    display: none;
}
.row-page-header {
    margin: 1em -15px 0;
}
.row-page-header .col-md-12 {
    padding-right: 0;
    padding-left: 0;
}
.nav-products .navbar-nav li.products>a,
.nav-orders .navbar-nav li.orders>a,
.nav-admin .navbar-nav li.admin>a,
.nav-settings .navbar-nav li.settings>a,
.nav-lotcheck .navbar-nav li.lotcheck>a
{
    font-weight: 700;
}
form.form-fields {
    margin: 1em 0;
}
form.form-fields .form-group label {
    text-align: right;
}
form.form-fields .glyphicon-calendar {
    position: absolute;
    z-index: 2;
    top: 9px;
    left: 25px;
}
form.form-fields .input-calendar {
    padding-left: 35px;
}
.breadcrumbs {
    list-style: none;
    margin: 0 0 1em 0;
    padding: 0;
    font-size: 14px;
}
.breadcrumbs li {
    display: inline-block;
}
.breadcrumbs .glyphicon-menu-right {
    font-size: .75em;
    color: #999;
}
.dropdown-advoptions {
    float: right;
}
.dropdown-advoptions .btn {
    background-color: inherit;
    font-size: 20px;
}
.dropdown-tbl-options .btn {
    background-color: inherit;
}
.dropdown-notification {
    width: 400px;
}
.dropdown-notification .divider {
    margin-top: 0;
    margin-bottom: 0;
}
.dropdown-notification li a {
    padding-top: .75em;
    padding-bottom: .75em;
    color: #333;
    white-space: normal !important;
}
.dropdown-notification li a span {
    font-size: 14px;
    color: #999;
    display: block;
}
.dropdown-menu>li>a.text-danger {
    color: #bc0f1d;
}
.dropdown-menu label {
    margin-left: .75em;
    font-weight: 400;
}
.dropdown-menu label input {
    margin-right: .5em;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    background-color: inherit;
}
.tab-content {
    margin-top: -3px;
}
.tab-content h3 {
    font-weight: 700;
    font-size: 18px;
    margin: 2em 0 1.5em;
    border-bottom: 1px solid #CCC;
    padding: .5em 0;
    color: #333;
}
.tab-content h3:first-child {
    margin-top: 0;
}
.row-metadata {
    margin-bottom: .75em;
}

.row-metadata .heading {
    text-align: right;
    font-weight: 700;
}
.sidebar {
    padding: 3.5em 0 0 2em;
}
.sidebar .row {
    margin-bottom: 1em;
    color: #666;
}
.sidebar .row:last-child {
    margin-bottom: 0;
}
.sidebar .row .glyphicon-ok-circle,
.sidebar .row .glyphicon-ok-sign
{
    float: right;
    font-size: 20px;
    margin-right: 1em;
}
.sidebar .glyphicon-ok-circle {
    color: #CCC;
}
.sidebar .panel-title {
    font-weight: 600;
    color: #666;
}
.sidebar ul li {
    margin-bottom: .5em;
}
.comment-container {
    margin: 2em 0 1em;
    padding: 1em 0 0;
    border-top: 1px solid #CCC;
}
.comment-container .comment {
    padding: 1em 1em 0;
}
.status-mini {
    color: #FFF;
    font-size: 12px;
    padding: .24em .5em;
    border-radius: 4px;
    font-weight: 700;
    text-transform: uppercase;
}
.status-mini.status-rejected,
.status-mini.status-danger
{
    background: #d04437;
}
.status-mini.status-pending,
.status-mini.status-info
{
    background: #31708f;
}
.status-mini.status-approved,
.status-mini.status-success
{
    background: #3c763d;
}
.status-mini.status-warning {
    background: #fcf8e3;
    color:#8a6d3b;
}
.status-mini.status-draft {
    background: #CCC;
    color: #FFF;
}
.status-mini.status-published {
    background: #3c763d;
    color: #FFF;
}
.status-mini.status-deleted {
    background: #d04437;
    color: #FFF
}

#marketing .glyphicon-file,
#marketing .glyphicon-open-file,
#marketing .glyphicon-warning-sign

{
    font-size: 75px;
    color: #CCC;
    padding: 5px 0 5px 35px;
}
#marketing .glyphicon-warning-sign {
    color: #a94442;
}
.notification-num {
    position: absolute;
    top: 20px;
    right: 0;
    display: inline-block;
    border-radius: 10px;
    background: #666;
    color: #FFF;
    width: 20px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
}
.projects-container .row {
    margin-bottom: .75em;
}
.table-projections th.year {
    background-color: #F3F3F3 !important;
}

.page-orders-items .total {
    margin: 4px 0 0 4px;
    font-weight: 700;
}
.page-orders-items .request-date {
    position: relative;
}
.tbl_features th {
    font-weight: 400;
    color: #000;
}
.asset-thumb-wrapper {
    padding: 1em 0;
}
.asset-thumb-wrapper a {
    border: 1px solid #337ab7;
    padding: .24em;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
}
.freight-hide {
    display:none;
}

.modal-dialog {
    width: 900px;
    margin: 30px auto;
}

.modal-subtitle{
    background: #f1f1f1;
    text-align: center;
    margin: 0 0 20px 0;
    font-weight: 700;
    padding: .24em 0;
}

.grid-detail{
    margin: 15px;
}

.rt-table{
    overflow: visible !important; 
}
.rt-tbody{
    overflow: visible !important;
}

.Toastify__toast--success {
    color: #155724 !important;
    background-color: #d4edda !important;
    border-color: #c3e6cb !important;
}
.no_left_padding {
    padding-left: 0px;
}

.li-disabled {
    opacity:0.6;          
}

/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}
body {
    /* Margin bottom by footer height */
    margin-bottom: 60px;
}
.footer {
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 50px;
    background-color: #f5f5f5;
    font-size: 12px;
}
.footer p {
    margin-top: 1em;
    color: #CCC;
}

/* data-table styles */
.wrap-text-cell {
    white-space: normal !important;
}

.overflow-visible {
    overflow: visible !important;
}

/* hack due to react-bootstrap version and css style mismatch */
.badge[bg=warning] {
    background-color: #ffc107;
}
